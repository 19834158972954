@import '~@which/seatbelt/src/styles/styles';

.gardeningContactUsWrapper {
  background-color: $sb-colour-background-subtle;
  padding: $sb-spacing-m 0;
}

.gardeningContactUsHeading {
  grid-column-start: 2;
  grid-column-end: 12;
  margin: $sb-spacing-m 0;
}

.gardeningContactUsMessageWrapper {
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row: auto;
  max-width: 100%;
}

.gardeningContactUsSubmit {
  margin: $sb-spacing-m 0;
}

.gardeningContactUsSelectIssue {
  width: 100%;
}

.gardeningContactUsMemberNumber {
  display: flex;
  flex-direction: column;
  input {
    margin: $sb-spacing-m 0;
  }
}

.gardeningContactUsForm {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: $sb-spacing-l;
}

.gardeningContactUsMemberRadio {
  display: flex;
  flex-direction: column;
}

.gardeningContactUsInline {
  display: inline;
  input {
    margin-right: $sb-spacing-s;
    width: $sb-spacing-l;
  }
}

.gardeningContactUsFormLeftColumn {
  grid-column-start: 2;
  grid-column-end: 12;
  width: 100%;

  @include sb-respond-to(medium-up) {
    grid-column-start: 2;
    grid-column-end: 7;
  }
}

.gardeningContactUsFormRightColumn {
  grid-column-start: 2;
  grid-column-end: 12;
  width: 100%;

  @include sb-respond-to(medium-up) {
    grid-column-start: 7;
    grid-column-end: 12;
  }
}

.gardeningContactUsTextArea {
  max-width: 100%;
  width: 100%;
  padding: $sb-spacing-s;
}

.gardeningContactUsRecRight {
  grid-column-start: 2;
  grid-column-end: 12;
  @include sb-respond-to(large) {
    grid-column-start: 7;
    grid-column-end: 12;
  }
}

.gardeningContactUsRecLeft {
  grid-column-start: 2;
  grid-column-end: 12;
  @include sb-respond-to(large) {
    grid-column-start: 2;
    grid-column-end: 7;
  }
}

.gardeningContactUsFormField :first-child {
  min-width: unset;
}

.numberInput::-webkit-outer-spin-button,
.numberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numberInput {
  -moz-appearance: textfield;
}

.whichMemberLabel {
  margin-bottom: $sb-spacing-xs;
}

.processing {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: $sb-spacing-3xl 0;
}

.processingLoader {
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  text-align: center;
  svg {
    margin-top: $sb-spacing-s;
  }
}

.messageSent {
  margin-top: $sb-spacing-m;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: $sb-spacing-l;
}

.messageSentBlock {
  text-align: center;
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row: auto;
  max-width: 100%;
}
