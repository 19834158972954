@import '~@which/seatbelt/src/styles/styles';
@import '../../../styles/mixins/wrapper';

$local-header-height: 45px;

.breadcrumb {
  border-bottom: $sb-border-width-s solid $sb-colour-border-muted;
  height: $local-header-height;
  position: relative;
  z-index: 100;
  background-color: $sb-colour-background-default;
  width: 100%;

  &Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @media only screen and (min-width: 1440px) {
      padding-right: $sb-spacing-l;
    }

    @include w-page-wrapper(true);
  }

  &List {
    display: flex;
    height: 100%;
    align-items: center;

    @include sb-reset-list;

    @include sb-respond-to(print) {
      flex-direction: column;
      list-style-type: decimal;
      list-style-position: inside;
    }
  }

  &Nav {
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
  }

  &NoLink {
    display: flex;
  }

  &Home {
    &Mobile {
      @include sb-respond-to(medium-up) {
        display: none;
      }
    }

    &IconMobile {
      width: 11px;
      height: 11px;

      @include sb-respond-to(medium-up) {
        display: none;
      }
    }

    &AriaText {
      @include sb-visually-hidden;
    }
  }

  &Item {
    &::before {
      display: none;

      @include sb-respond-to(medium-up) {
        border-right: $sb-border-width-s solid $sb-colour-border-default;
        content: '';
        display: inline-block;
        height: 0.9em;
        transform: rotate(15deg);
        margin: 0 $sb-spacing-xs -0.2em;
      }
    }
  }

  &ItemVertical {
    &::before {
      border-right: $sb-border-width-s solid $sb-colour-border-default;
      content: '';
      display: inline-block;
      height: 0.9em;
      transform: rotate(15deg);
      margin: 0 $sb-spacing-xs -0.2em;
    }
  }

  &Item:first-of-type {
    margin-left: 0;

    &:before {
      display: none;
    }

    @include sb-respond-to(medium-up) {
      margin-left: $sb-spacing-xs;
    }
  }

  &HeaderLink,
  &ItemLink {
    svg {
      margin-right: 2px;
      width: 11px;
    }

    @include sb-respond-to(medium-up) {
      svg {
        display: none;
      }
    }
  }

  &ItemLink {
    margin-left: 2px;
  }

  &CurrentPage {
    display: none;

    @include sb-respond-to(print) {
      display: list-item;
    }
  }
}

.hideOnMobile {
  display: none;

  @include sb-respond-to(medium-up) {
    display: inline-block;
  }
}
