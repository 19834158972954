@import '~@which/seatbelt/src/styles/styles';

.featuredContentGridItem {
  @include sb-respond-to(large) {
    align-self: center;
  }

  &:last-child {
    margin-bottom: $sb-spacing-3xl;
  }
}

.featuredArticleCard {
  &Wrapper {
    display: flex;
    justify-content: center;

    margin-top: $sb-spacing-xl;

    @include sb-respond-to(large) {
      align-self: center;
      margin: $sb-spacing-xl;
    }

    @include sb-respond-to(xlarge) {
      margin: $sb-spacing-xl 0;
    }
  }
}

.latestNews {
  margin: $sb-spacing-l 0;

  @include sb-respond-to(large) {
    margin-top: $sb-spacing-xl;
  }

  @include sb-respond-to(xlarge) {
    margin-top: $sb-spacing-3xl;
  }
}
