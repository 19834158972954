@import '~@which/seatbelt/src/styles/styles';

.contactUsWrapper {
  background-color: $sb-colour-background-subtle;
  padding: $sb-spacing-m 0;
}

.contactUsHeading {
  grid-column-start: 2;
  grid-column-end: 12;
  margin: $sb-spacing-m 0 0 0;
}

.contactUsMessageWrapper {
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row: auto;
  max-width: 100%;
}

.contactUsSubmit {
  margin: $sb-spacing-m 0;
}

.dropdownWrapper {
  width: 100%;
}

.contactUsSelectIssue {
  width: 100%;
}

.contactUsMemberNumber {
  display: flex;
  flex-direction: column;
  input {
    margin: $sb-spacing-m 0;
  }
}

.contactUsForm {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: $sb-spacing-m;
}

.contactUsMemberRadio {
  display: flex;
  flex-direction: column;
}

.contactUsInline {
  display: inline;
  input {
    margin-right: $sb-spacing-s;
    width: $sb-spacing-m;
  }
}

.contactUsFormLeftColumn {
  grid-column-start: 2;
  grid-column-end: 12;
  width: 100%;

  @include sb-respond-to(medium-up) {
    grid-column-start: 2;
    grid-column-end: 7;
  }
}

.contactUsFormRightColumn {
  grid-column-start: 2;
  grid-column-end: 12;
  width: 100%;

  @include sb-respond-to(medium-up) {
    grid-column-start: 7;
    grid-column-end: 12;
  }
}

.contactUsTextArea {
  max-width: 100%;
  width: 100%;
  padding: $sb-spacing-s;
}

.contactUsRecRight {
  grid-column-start: 2;
  grid-column-end: 12;
  @include sb-respond-to(large) {
    grid-column-start: 7;
    grid-column-end: 12;
  }
}

.contactUsRecLeft {
  grid-column-start: 2;
  grid-column-end: 12;
  @include sb-respond-to(large) {
    grid-column-start: 2;
    grid-column-end: 7;
  }
}

.contactUsFormField :first-child {
  min-width: unset;
}

.contactUsFormField {
  &LabelWrapper {
    background-color: red;
  }
}

.numberInput::-webkit-outer-spin-button,
.numberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numberInput {
  -moz-appearance: textfield;
}

.whichMemberLabel {
  margin-bottom: $sb-spacing-xs;
}

.processing {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: $sb-spacing-3xl 0;
}

.processingLoader {
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  text-align: center;
  svg {
    margin-top: $sb-spacing-s;
  }
}

.messageSent {
  margin-top: $sb-spacing-m;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: $sb-spacing-m;
}

.messageSentBlock {
  text-align: center;
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row: auto;
  max-width: 100%;
}

label[for='ContactForm[device]'] {
  @include sb-respond-to(medium-up) {
    margin-bottom: $sb-spacing-xl;
  }
}

label[for='ContactForm[problem]'] {
  @include sb-respond-to(medium) {
    margin-bottom: $sb-spacing-xl;
  }
}
