@import '~@which/seatbelt/src/styles/styles';

.sharingOptionsVertical {
  margin-top: $sb-spacing-xl;
  margin-bottom: $sb-spacing-m; 
}

@include sb-respond-to(small) {
  .sharingOptionsHorizontal {
    margin-bottom: $sb-spacing-l;
  }
}
