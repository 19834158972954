@import '~@which/seatbelt/src/styles/styles';

.piCarouselSection {
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;

  &MobileCarousel {
    margin-bottom: 0;

    [class*='cardRowScrollWrapper'] {
      padding-left: 0;
      padding-right: $sb-spacing-s;
    }

    article {
      width: 278px;
      margin-right: $sb-spacing-s;
    }
  }
}
