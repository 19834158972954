@import '~@which/seatbelt/src/styles/styles';

.wrapper {
  background-color: #ebebeb; // @TODO: Should be SB colour
}

.textAndButton {
  padding: $sb-spacing-m $sb-spacing-l 0;

  @include sb-respond-to(medium) {
    padding: $sb-spacing-3xl;
  }

  @include sb-respond-to(large) {
    padding: $sb-spacing-3xl 0 $sb-spacing-3xl $sb-spacing-3xl;
  }
}

.button {
  margin-bottom: $sb-spacing-l;
  width: max-content;

  @include sb-respond-to(medium-up) {
    margin-bottom: 0;
  }
}

.imageContainer {
  height: 200px;
  overflow: hidden;
  position: relative;

  @include sb-respond-to(large) {
    height: 100%;
  }
}

.imageWrapper {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  height: fit-content;

  @include sb-respond-to(large) {
    height: 100%;
  }
}

.image {
  max-width: fit-content;

  @include sb-respond-to(large) {
    left: 50%;
    margin-left: -122px;
    position: absolute;
    top: 59px;
  }
}
