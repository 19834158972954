@import '~@which/seatbelt/src/styles/styles';

.fetchMore {
  &Wrapper {
    padding-bottom: $sb-spacing-xl;
    position: relative;
  }

  &Button {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
