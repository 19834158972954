@import '~@which/seatbelt/src/styles/styles';

.ABToolEntryPoint {
  border: $sb-border-width-s solid $sb-colour-border-muted;
  border-radius: $sb-border-radius-s;
  padding: $sb-spacing-l;
  margin-top: $sb-spacing-m;

  @include sb-respond-to(medium-up) {
    padding-bottom: $sb-spacing-l;
  }

  &QuestionVariant {
    border: $sb-border-width-m solid $sb-colour-border-action-focus;
    padding: $sb-spacing-m;

    @include sb-respond-to(medium-up) {
      padding-top: $sb-spacing-l;
      padding-bottom: $sb-spacing-l;
    }
  }

  &Badge {
    text-transform: uppercase;
    margin-bottom: $sb-spacing-s;

    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-m;
    }
  }

  &Title {
    margin-bottom: $sb-spacing-s;

    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-m;
    }
  }

  &BodyWrapper {
    @include sb-respond-to(medium-up) {
      display: flex;
    }
  }

  &Content {
    @include sb-respond-to(medium-up) {
      flex: 1;
      padding-right: $sb-spacing-s;
    }

    p {
      margin-bottom: $sb-spacing-m;
    }
  }

  .image {
    margin: $sb-spacing-l 0 0;
    max-width: 160px;
    display: block;

    @include sb-respond-to(medium-up) {
      flex: 1;
      margin: 0;
      max-width: 292px;
    }
  }
}
