@import '~@which/seatbelt/src/styles/styles';

.categories {
  &Wrapper {
    margin-bottom: $sb-spacing-m;
    width: 100%;
  }

  &LinkBlock {
    width: 100%;
  }
}
