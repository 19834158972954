@import '~@which/seatbelt/src/styles/styles';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: $sb-spacing-l 0;

  @include sb-respond-to(medium-up) {
    margin: $sb-spacing-3xl 0;
  }

  @include sb-respond-to(large) {
    flex-flow: row nowrap;
    justify-content: center;
  }

  .half {
    @include sb-respond-to(large) {
      align-self: flex-start;
    }

    &.gap {
      @include sb-respond-to(large) {
        margin: 0 4% 0 0;
      }
    }

    .image {
      margin: 0 0 3px;
      border-radius: $sb-border-radius-full;
      width: 123px;
      height: 123px;

      @include sb-respond-to(medium-up) {
        margin: 0 0 $sb-spacing-xl;
        width: 230px;
        height: 230px;
      }

      @include sb-respond-to(large) {
        width: 318px;
        height: 318px;
      }
    }

    .copy {
      margin: $sb-spacing-m 0;
    }

    .button {
      margin: $sb-spacing-m 0 0;
    }

    .realLifeStoryHeading {
      text-align: left;
    }
  }
}
