@import '~@which/seatbelt/src/styles/styles';

.tableWrapper {
  margin-bottom: $sb-spacing-xl;
  text-align: center;

  &Desktop {
    text-align: left;
  }

  .section {
    border-bottom: solid 1px $sb-colour-border-disabled;
    margin-top: $sb-spacing-xl;

    @include sb-respond-to(large) {
      border-bottom: none;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .header {
    background-color: $sb-colour-border-inverse;
    margin-top: 0;
    position: sticky;
    position: -webkit-sticky; /* For Safari */
    top: 0;
    z-index: 2;

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      list-style: none;
      margin: 0;
      padding: $sb-spacing-l 0;

      @include sb-respond-to(large) {
        grid-template-columns: repeat(5, 1fr);
      }

      li {
        border-right: solid 1px $sb-colour-border-disabled;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 $sb-spacing-xs;

        &:last-of-type {
          border-right: none;
        }
      }
    }

    .selectWrapper {
      align-items: center;
      align-self: center;
      display: inline-flex;
      margin-top: $sb-spacing-m;
      position: relative;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        border: none;
        color: $sb-colour-text-link-default;
        cursor: pointer;
        font-size: $sb-font-size-16;
        padding-right: $sb-spacing-m;
        text-decoration: underline;
        width: 135px;
      }

      svg {
        pointer-events: none;
        position: absolute;
        right: 0px;
      }
    }
  }

  .productDetails {
    border-bottom: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: $sb-spacing-l;

    @include sb-respond-to(large) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .productFeatureRow {
    border-top: solid 1px $sb-colour-border-disabled;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: $sb-spacing-s $sb-spacing-xs;

    &Grey {
      background-color: $sb-colour-background-muted;
    }

    @include sb-respond-to(large) {
      grid-template-columns: repeat(5, 1fr);
      padding: 0;

      &:hover {
        background-color: $sb-colour-background-brand-yellow;
      }
    }

    & > div {
      padding: $sb-spacing-s;

      @include sb-respond-to(large) {
        border-right: solid 1px $sb-colour-border-disabled;
        padding: $sb-spacing-m;
        position: relative;
        text-align: center;

        &::after,
        &::before {
          background-color: $sb-colour-background-default;
          content: '';
          height: 13px;
          position: absolute;
          right: -2px;
          transform: translate(-1px, 0);
          width: 1px;
        }

        &::before {
          bottom: 0;
        }

        &::after {
          top: 0;
        }

        &:hover {
          background-color: $sb-colour-background-brand-gold;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    & > div:nth-of-type(2) {
      border-right: solid 1px $sb-colour-border-disabled;
    }

    .rowLabel {
      grid-column: 1 / -1;
      grid-row: 1;
      padding: 0 0 $sb-spacing-s 0;
      word-break: break-all;

      @include sb-respond-to(large) {
        grid-column: 1;
        grid-row: 1;
        padding: $sb-spacing-m;
        position: relative;
        text-align: left;

        &::after,
        &::before {
          background-color: $sb-colour-background-default;
          content: '';
          height: 13px;
          position: absolute;
          right: -2px;
          transform: translate(-1px, 0);
          width: 1px;
        }

        &::before {
          bottom: 0;
        }

        &::after {
          top: 0;
        }

        span {
          font-weight: $sb-font-weight-medium;

          @include sb-respond-to(large) {
            font-weight: $sb-font-weight-regular;
            word-break: break-word;
          }
        }
      }

      &Text {
        margin-right: $sb-spacing-s;
      }

      &WithPadlock {
        @include sb-respond-to(large) {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
    }

    .tableDataRowValue .tableValue {
      word-break: break-all;

      @include sb-respond-to(large) {
        line-height: 25px;
        word-break: normal;
        word-wrap: break-word;
      }
    }

    .padlock {
      vertical-align: inherit;
    }
  }

  .filler {
    display: none;
    background: transparent !important;
    border: none !important;

    @include sb-respond-to(large) {
      display: block;
    }
  }

  .accordionWrapper {
    margin-bottom: $sb-spacing-2xl;
    margin-top: $sb-spacing-2xl;

    button[class*='Accordion_accordionItemHeadingButton'] {
      padding-left: $sb-spacing-l;
      padding-right: $sb-spacing-l;

      & svg {
        margin: 0;
      }
    }
  }
}

.compareSection {
  padding-top: 0;
}

.tickIcon,
.crossIcon {
  height: 20px;
  width: 20px;

  path {
    fill: $sb-colour-text-positive;
  }

  &.crossIcon path {
    fill: $sb-colour-text-negative;
  }
}

.prosAndCons {
  .prosConsList {
    text-align: left;

    span {
      text-align: left;
    }

    img {
      mix-blend-mode: multiply;
    }
  }
}

.whereToBuy {
  .tableDataRowValue {
    text-align: left;
  }
}

.offersList {
  padding: 0;
  margin: 0;
}

.offersListItem {
  list-style-type: none;
  margin: 0 0 $sb-spacing-m;
  text-align: left;
}

.offersListItem:last-child {
  margin: 0;
}

.link,
.typicalPrice {
  margin-left: $sb-spacing-s;
}

.typicalPrice {
  color: $sb-colour-text-muted;
}

.mobileGreyRow {
  background-color: $sb-colour-background-muted;
}

.expertReview {
  .padlockIcon {
    margin-right: $sb-spacing-s;

    @include sb-respond-to(large) {
      margin-top: 2px;
    }
  }

  .verdict {
    margin-bottom: $sb-spacing-s;

    @include sb-respond-to(large) {
      margin-bottom: $sb-spacing-m;
    }
  }

  div.editorialReviewSection {
    padding: $sb-spacing-s;
    text-align: left;

    @include sb-respond-to(large) {
      padding: $sb-spacing-m;
    }
  }
}

.visuallyHidden {
  @include sb-visually-hidden;
}

.compareTray {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.groupTooltip {
  text-align: left;
  margin: 0 0 $sb-spacing-s $sb-spacing-s;

  @include sb-respond-to(large) {
    margin: 0 0 0 $sb-spacing-s;
  }
}

.sectionLabelWrapper {
  padding: $sb-spacing-m 0 $sb-spacing-s;

  @include sb-respond-to(large) {
    background-color: $sb-colour-background-muted;
    padding: $sb-spacing-xl 0 $sb-spacing-m 13px;
    width: 100%;
  }
}
