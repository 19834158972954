@import '~@which/seatbelt/src/styles/styles';

.wrapper {
  display: flex;
  margin: 0 0 $sb-spacing-l;

  .inputWrapper {
    position: relative;
    width: 100%;

    @include sb-respond-to(medium-up) {
      margin-right: $sb-spacing-m;
      max-width: 548px;
    }

    @media only screen and (min-width: 1460px) {
      max-width: 581px;
    }

    input {
      max-width: 100%;
      height: 45px;
      font-size: $sb-font-size-18;
      padding-left: $sb-spacing-m;
      padding-right: $sb-spacing-2xl;

      @include sb-respond-to(medium-up) {
        padding-right: $sb-spacing-m;
      }
    }

    .mobileSearchButton {
      position: absolute;
      right: 2px;
      top: 3px;
      width: 42px;
      height: 40px;
      border-radius: $sb-border-radius-s;
      padding: 0;
      justify-content: center;
      border: none;

      @include sb-respond-to(medium-up) {
        display: none;
      }

      .searchIcon {
        margin: 0;
        width: 20px;
        height: 20px;
        pointer-events: none;
      }
    }
  }

  .desktopSearchButton {
    display: none;

    @include sb-respond-to(medium-up) {
      display: inline-flex;
      height: auto;
      padding: 0 $sb-spacing-xl;
    }
  }
}
