@import '~@which/seatbelt/src/styles/styles';

.icon {
  background-color: white;
  width: 68px;
  height: 68px;
  margin: 0 auto $sb-spacing-m;
  border-radius: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta {
  margin-bottom: $sb-spacing-xs;

  &Wrapper {
    display: block;
    text-align: center;
    min-width: 230px;
    max-width: 100%;
    padding: $sb-spacing-m;
    background-color: $sb-colour-background-brand-gold;

    p {
      margin-bottom: $sb-spacing-m;
    }

    @include sb-respond-to(medium-up) {
      display: none;
    }

    &Desktop {
      display: none;
      max-width: 400px;

      @include sb-respond-to(medium-up) {
        display: block;
      }
    }
  }
}
