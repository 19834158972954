@import '~@which/seatbelt/src/styles/styles';

.badge {
  height: 30px;
  width: 30px;
  border-radius: $sb-border-radius-full;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin: 0 $sb-spacing-s 0 0;

  &.blue {
    color: sb-color('sb-colour-text-link-default');
  }

  &.white {
    color: $sb-colour-text-inverse;
  }

  &.dark {
    background-color: $sb-colour-background-inverse;
  }

  &.light {
    background-color: $sb-colour-background-default;
  }
}

.centre {
  display: flex;
  align-items: center;
}

.padlock {
  min-width: 13px;
  align-self: flex-start;
  margin: 3px $sb-spacing-s 0 0;

  @include sb-respond-to(medium-up) {
    align-self: center;
    margin: 0 $sb-spacing-s 0 0;
  }
}

.signup {
  margin: 0 $sb-spacing-xs 0 0;
}
