@import '~@which/seatbelt/src/styles/styles';

.skipButton {
  background-color: $sb-colour-background-default;
  border-radius: $sb-border-radius-s;
  border: none;
  color: sb-color('sb-colour-text-link-default');
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: center;
  text-decoration: underline;
  width: 0;

  &:focus {
    z-index: 10000;
    height: 65px;
    width: fit-content;
    max-width: 172px;
    padding: $sb-spacing-m;
  }
}
