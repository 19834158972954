@import '~@which/seatbelt/src/styles/styles';

.adviceCards {
  @include sb-respond-to(medium-up) {
    display: flex;
    flex-wrap: wrap;
  }

  &Link {
    display: block;
    padding: $sb-spacing-m 0 $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      flex: 0 0 calc(100% / 2);
      padding-left: $sb-spacing-m;
      padding-right: $sb-spacing-m;
    }

    @include sb-respond-to(large) {
      flex: 0 0 calc(100% / 3);
    }
  }

  &LinkTitle,
  &LinkStrapline {
    color: $sb-colour-text-default;
    margin-bottom: $sb-spacing-m;
  }

  &LinkIcon {
    position: relative;
    left: 0;
    top: -1px;
    margin-right: $sb-spacing-xs;
  }

  &LinkAnimation {
    color: $sb-colour-text-link-default;
  }
}
