@import '~@which/seatbelt/src/styles/styles';

.subscriptionTiers {
  margin-top: $sb-spacing-3xl;
  border-radius: $sb-border-radius-s;
  border: $sb-border-width-s solid $sb-colour-background-disabled;
  background: $sb-colour-background-muted;
  padding: $sb-spacing-xl;

  .tierList {
    list-style-type: none;
    padding-left: 0;
    margin:  $sb-spacing-m 0;

    @include sb-respond-to('large') {
      display: flex;
      flex-wrap: wrap;
      gap: $sb-spacing-s;
    }
  }

  .tier {
    flex: 1;
    min-width: 193px;
    max-width: 400px;
    min-height: 190px;
    padding: $sb-spacing-m;
    margin-left: auto;
    margin-right: auto;
    background-color: $sb-colour-background-default;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:not(:last-of-type) {
      margin-bottom: $sb-spacing-s;

      @include sb-respond-to('large') {
        margin-bottom: 0;
      }
    }

    @include sb-respond-to('large') {
      max-width: none;
    }

    &Title {
      text-transform: uppercase;
    }

    &IntroPriceText,
    &Cta {
      display: block;
    }
  }

  .offer {
    background-color: $sb-colour-background-brand-blue;
    text-align: center;
    padding: $sb-spacing-m $sb-spacing-l;

    &Title {
      margin-bottom: $sb-spacing-s;
    }
  }
}
