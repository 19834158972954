@import '~@which/seatbelt/src/styles/styles';

.loginPage {
  margin-bottom: $sb-spacing-3xl;
  margin-top: $sb-spacing-xl;

  &Title {
    margin: 105px 0 $sb-spacing-m;

    @media only screen and (max-width: 767px) {
      margin: 65px 0 $sb-spacing-xl;
      width: 100%;
    }
  }

  &.error h1,
  &.correct h1 {
    margin-top: $sb-spacing-xl;
  }

  &SignupLink {
    @media only screen and (max-width: 767px) {
      text-align: center;
    }
  }

  &FormFooter {
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
  }

  &StayLoggedInDiv {
    justify-content: flex-start;
    display: inline-flex;

    label {
      font-size: $sb-font-size-18;
      border: $sb-border-width-m solid transparent;
      padding: 0px 0 0 $sb-spacing-xl;
      margin: -3px 0 0 -28px;
      border-radius: $sb-border-radius-s;
    }
    &:focus-within label {
      border: $sb-border-width-m solid $sb-colour-border-action-focus;
    }
  }

  &UntickText {
    padding-left: $sb-spacing-xl;
  }

  &ForgotPassword {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;

    @media only screen and (max-width: 767px) {
      margin-bottom: $sb-spacing-xl;
    }

    &Link {
      margin-bottom: -25px;
      font-size: $sb-font-size-16;
    }
  }

  &Submit {
    margin: $sb-spacing-l 0 $sb-spacing-xs;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;

    &:focus {
      //TODO: move to storybook MEXS-162
      border: 3px solid $sb-colour-border-action-focus;
    }

    @media only screen and (min-width: 767px) {
      width: 229px;
    }
  }
}

.loginPagePasswordField {
  margin-bottom: $sb-spacing-m;
}
