@import '~@which/seatbelt/src/styles/styles';

$sb-button-height: 44px;

/* Global used to provide styles to override the styles injected by OneTrust
 * Specific styles pulled from packages/seatbelt/src/components/Button/Button.module.scss
 */
:global(button#ot-sdk-btn.ot-sdk-show-settings) {
  @include sb-text-body;
  @include sb-font-medium;

  color: sb-color('sb-color-white');
  background-color: $sb-colour-background-action-primary-default;
  border: $sb-border-width-s solid $sb-colour-border-action-focus;
  height: $sb-button-height;
  padding: $sb-spacing-s $sb-spacing-m;
  border-radius: calc(#{$sb-button-height} * #{$sb-border-radius-full});
  transition: unset;

  &:hover {
    background-color: darken($sb-colour-background-action-primary-default, 20%);
    border: $sb-border-width-s solid darken($sb-colour-border-action-focus, 20%);
  }
}
