@import '~@which/seatbelt/src/styles/styles';

.passwordField {
  margin-bottom: $sb-spacing-xl;
  position: relative;

  Input {
    padding: $sb-spacing-m $sb-spacing-xl $sb-spacing-m $sb-spacing-s;
  }

  &List {
    letter-spacing: 0;
    @include sb-font-size-line-height(16px, 20px);
    font-family: $sb-font-family-regular;
    margin-bottom: $sb-spacing-s;

    &Item {
      font-family: $sb-font-family-medium;
      @include sb-font-size-line-height(14px, 23px);
      margin-bottom: 0;

      span {
        &::before {
          color: $sb-colour-text-default;
          top: 0;
          font-size: 26px;
        }
      }

      &Intro {
        font-size: 16px;
        margin-bottom: 0;
        padding-left: 0;

        span::before {
          content: none;
        }
      }
    }

    &Valid {
      color: $sb-colour-text-positive;

      span {
        padding-left: 1px;
      }
    }

    &InValid {
      color: $sb-colour-text-negative;

      span {
        padding-left: 1px;
      }
    }

    &Neutral {
      color: $sb-colour-text-default;
    }
  }

  &Input {
    position: relative;
  }
}

.animatedEye {
  height: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;

  label {
    cursor: pointer;
    display: flex;
    z-index: 1;

    &:focus-within {
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}
