@import '~@which/seatbelt/src/styles/styles';

.CTAContainer {
  background-color: #efefef;
  padding: $sb-spacing-s 0 $sb-spacing-s $sb-spacing-m;

  p[class*='p-article'] {
    margin: 0;
  }
}
